import { useEffect } from "react";
import { languageMap } from "../constants/constants.";

const useLanguageEffect = (i18n, setSelectedCountry, getFlag, countries) => {
  useEffect(() => {
    const language = languageMap[i18n.language];
    if (language) {
      i18n.changeLanguage(language.toLowerCase());
      setSelectedCountry({
        name: language,
        flag: getFlag(countries, language),
      });
    }
  }, [i18n, i18n.language, setSelectedCountry, getFlag, countries]);
};

export default useLanguageEffect;
